import { render, staticRenderFns } from "./Listas.vue?vue&type=template&id=6d5bdcb6&scoped=true"
import script from "./Listas.vue?vue&type=script&lang=js"
export * from "./Listas.vue?vue&type=script&lang=js"
import style0 from "./Listas.vue?vue&type=style&index=0&id=6d5bdcb6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d5bdcb6",
  null
  
)

export default component.exports