<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>{{$route.meta.title}}</h1>
    </div>
    <br />
    <DataGridV2 :config="dataGridConfig" :data="dataSourceTable.data"  @actions="dataGridActions" ref="dataTable">
      <div class="row">
        <div class="col-sm-2">
          <button class="btn warning-btn" @click="loadListas()">Recargar</button>
        </div>
        <div class="col-sm-2">
          <button  class="btn secondary-btn" @click="openModalForm = true">Editar</button>
        </div>
      </div>

    </DataGridV2>

    <EditarLista v-if="openModalForm" :lista_id="lista_id" @cerrar="openModalForm = false" @actualizar="loadListas()"></EditarLista>

  </div>
</template>

<script>
import DataGridV2 from '@/components/DataGridV2'
import Modal from '@/components/Modal'
import api from '@/apps/cobranza_admin/api/listas.api'
import EditarLista from '@/apps/cobranza_admin/components/EditarLista'
import moment from 'moment'

export default {
  name: "Listas",
  data(){
    return {
      openModalForm: false,
      openModalEliminar: false,
      openModalDetalle: false,
      accionModal: 'crear',
      dataGridConfig: {
        name: 'listas_cobranza',
        cols: {
          nombre: 'Nombre',
          updated_at: 'Fecha de ultima edición',
        }
        ,paginator: {
          pagina_actual: 1
          ,total_registros: 1
          ,registros_por_pagina: 20
        }
        ,mutators: {
          updated_at: function(val) {
            return moment(val).format('YYYY-MM-DD HH:mm');
          }
        },
        search: true,
        select_items: true
      },
      options: {
        page: 1,
        order_col: 'id',
        order_dir: 'desc',
        limit: 20,
        filters: []
      },
      eliminar: false,
      dataSourceTable: [],
      lista: null,
      lista_id: null
    }
  },
  components: {
    DataGridV2,
    Modal,
    EditarLista
  },
  methods: {
    dataGridActions: function(tipo, valor) {
      if (tipo === 'options'){
        this.options = valor;
        this.loadListas();
      }

      if (tipo === 'selected') {
        this.lista_id =  valor.length > 0 ? valor[0].id : null;
      }
    },

    async loadListas() {
        let query = this.options;
        let dataSource = await api.listas(query)
        this.dataSourceTable = dataSource.data;
        this.dataGridConfig.paginator.pagina_actual = this.dataSourceTable.current_page;
        this.dataGridConfig.paginator.total_registros = this.dataSourceTable.total;
        this.dataGridConfig.paginator.registros_por_pagina = parseInt(this.dataSourceTable.per_page);
    },
  }
}
</script>

<style scoped lang="scss">
.btn-detalle{
  font-family: "NunitoRegular";
  padding: 0.5rem !important;
}
.btn-detalle:hover{
  background: transparent;
  color: #545b62;
}
</style>
